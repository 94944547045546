<template>
  <div>
    <Header :has-login="hasLogin" :username="user.username"/>

    <div class="mdiary">
      <h2>{{ diary.title }}</h2>
      <el-divider></el-divider>
      <!--      class="markdown-body" 使用github的markdown样式-->
      <!--      <div class="markdown-body" v-html="diary.content"></div>-->
      <mavon-editor
          class="md"
          :value="diary.content"
          :subfield="prop.subfield"
          :defaultOpen="prop.defaultOpen"
          :toolbarsFlag="prop.toolbarsFlag"
          :editable="prop.editable"
          :scrollStyle="prop.scrollStyle"
          :boxShadow="prop.boxShadow"
      >
      </mavon-editor>
      <div style="text-align: center; margin-top: 30px; padding: 20px">
        <el-button v-if="ownDiary" type="primary " @click="editDiary">编辑</el-button>
        <el-button v-if="ownDiary" type="danger" @click="deleteDiary">删除</el-button>

        <el-button v-if="(diary.type==2||diary.type==3)&&diary.status==0" type="success" @click="approveDiary">审批</el-button>
        <el-button v-if="(diary.type==2||diary.type==3)&&diary.status==0" type="warning" @click="withdrawDiary">退审</el-button>
      </div>
    </div>


  </div>
</template>

<script>
import Header from "../components/Header";
import 'github-markdown-css'

export default {
  name: "DiaryDetail",
  components: {Header},
  data() {
    return {
      diary: {
        id: '',
        user: '',
        title: '',
        content: '',
        relevantInfo: [],
        status: '',
        type: ''
      },
      value: '',
      ownDiary: false,
      hasLogin: false,
      allUserInfo: [],
      user: {
        id: '',
        username: '',
        avatar: '',
        email: ""
      },
    }
  },
  computed: {
    prop() {
      let data = {
        subfield: false,// 单双栏模式
        defaultOpen: 'preview',//edit： 默认展示编辑区域 ， preview： 默认展示预览区域
        editable: false,
        toolbarsFlag: false,
        scrollStyle: true,
        boxShadow: false,
        // fontSize: '50px'
      }
      return data
    }
  },
  methods: {
    approveDiary() {
      this.$confirm('是否执行审批', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const _this = this
        console.log(_this.diary.id)
        this.$axios.post('/todo/approveTodo?diaryId=' + _this.diary.id, {}, {
              headers: {
                "Authorization": localStorage.getItem("token")
              }
            }
        ).then(res => {
          this.$message({
            type: 'success',
            message: '审批成功!'
          })
          this.$router.push("/todo")
        });
      });

    },
    withdrawDiary() {
      this.$confirm('是否执行退审', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const _this = this
        console.log(_this.diary.id)
        this.$axios.post('/todo/withdrawTodo?diaryId=' + _this.diary.id, {}, {
              headers: {
                "Authorization": localStorage.getItem("token")
              }
            }
        ).then(res => {
          this.$message({
            type: 'success',
            message: '退审成功!'
          })
          this.$router.push("/todo")
        });
      });

    },
    deleteDiary() {
      this.$confirm('是否刪除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const _this = this
        console.log(_this.diary.id)
        this.$axios.delete('/diary/' + _this.diary.id, {
              headers: {
                "Authorization": localStorage.getItem("token")
              }
            }
        ).then(res => {
          this.$message({
            type: 'success',
            message: '刪除成功!'
          })
          this.$router.push("/diary")
        });
      });

    },
    editDiary() {
      this.$router.push({ name: 'DiaryEdit', params: { diaryId: this.diary.id }})
    }
  },
  created() {
    const diaryId = this.$route.params.diaryId
    console.log(diaryId)
    if (diaryId) {
      this.$axios.get('/diary/' + diaryId, {
            headers: {
              "Authorization": localStorage.getItem("token")
            }
          }
      ).then(res => {
        const diary = res.data.data
        console.log("日报信息" + JSON.stringify(diary))
        this.diary.id = diary.id
        this.diary.title = diary.title
        this.diary.type = diary.type
        this.diary.status = diary.status
        this.diary.content = diary.content

        //用于将markdown的数据渲染为相应的html
        // let MarkdownIt = require("markdown-it")
        // let md = new MarkdownIt()
        // let result = md.render(diary.content)
        // this.diary.content = result

        if (this.$store.getters.getUser) {
          this.ownDiary = (diary.userId === this.$store.getters.getUser.id)
          console.log("用户信息id：" + this.$store.getters.getUser.id)
          this.user.id = this.$store.getters.getUser.id
          this.user.username = this.$store.getters.getUser.username
          this.user.avatar = this.$store.getters.getUser.avatar
          this.user.email = this.$store.getters.getUser.email

          this.hasLogin = true
        }

        this.$axios.get('/user').then(res => {
          this.allUserInfo = res.data.data
        })
      })
    }
  }
}
</script>

<style scoped>

.mdiary {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  margin-top: 25px;
  padding: 20px 15px;
  border-radius: 10px;
}
</style>
